<template>
  
  <div class="mx-5 my-5">
    <spinner v-if="fetching"/>
    <v-row v-else>
      <v-col md="12" class="d-flex" style="justify-content: space-between; align-items: center">
        <h2>{{ $t('addinvoiceTemplate') }}</h2>
        <h2>{{ invoiceTypeLabel }}</h2>
      </v-col>
      <v-col md="3" class="py-3">
        <v-text-field v-model="invoice.name" :label="$t('Template Name')" outlined dense color="#757575" item-text="name"
          item-value="id"></v-text-field>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('cost center')"  v-model="invoice.cost_center_id"
          endPoint="/accounting/cost-center/auto-complete" outlined dense returnObject />
      </v-col>
      <v-col md="3" class="py-3" style="display: flex; justify-content: center; align-items: flex-start">
        <v-radio-group :dark="$store.state.isDarkMode" row v-model="invoice.discount_type" class="mt-0">
          <template v-slot:label><div style="font-size: 14px;">{{ $t('Discount') }}</div></template>
          <v-radio value="PERCENTAGE"><template v-slot:label><div style="font-size: 14px;">{{ $t('Percent') }}</div></template></v-radio>
          <v-radio value="FIXED"><template v-slot:label><div style="font-size: 14px;">{{ $t('Value') }}</div></template></v-radio>
        </v-radio-group>
      </v-col>
      <v-col md="3" class="py-3">
        <v-text-field :label="$t('Discount')" :dark="$store.state.isDarkMode" type="number" outlined dense v-model="invoice.discount_value"></v-text-field>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('Inventory')" outlined dense v-model="invoice.inventory_id" no-filter
          endPoint="/inventories/auto-complete" returnObject clearable @input="matchMaterialInventoriesHandler" />
      </v-col>
      <v-col v-if="invoice.table_view.length && invoice.table_view[14].value" md="3" class="py-3">
        <v-autocomplete  :label="$t('Tax Code')" outlined dense color="#757575" item-text="code" item-value="id"
          :items="taxes" v-model="invoice.tax_id" clearable @input="matchMaterialTax_IdHandler"></v-autocomplete>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('Tax Account')" outlined dense v-model="invoice.tax_account_id"
          endPoint="/accounting/account/auto-complete" returnObject clearable @input="matchMaterialTax_AccHandler"/>
      </v-col>
      <v-col v-if="invoice.table_view.length && invoice.table_view[16].value" md="3" class="py-3">
        <v-text-field  :label="$t('Tax Percent')" v-model="invoice.tax_percent" type="number" outlined @input="matchMaterialTax_PercentHandler"
          dense></v-text-field>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('Discount Account')" outlined dense v-model="invoice.discount_account_id"
          endPoint="/accounting/account/auto-complete" returnObject clearable />
      </v-col>
     
          <v-col md="3" class="py-3">
            <v-autocomplete :label="$t('Payment Method')" :dark="$store.state.isDarkMode" outlined dense color="#757575" item-text="label" item-value="value"
              no-filter :items="paymentMethods" v-model="invoice.payment_method"></v-autocomplete>
          </v-col>
          <v-col md="3" class="py-0" v-if="invoice.payment_method == 'CASH'">
            <AutoCompleteField :label="$t('hr.loan.cash')" :cash="1" v-model="invoice.cash_account_id"
              endPoint="/accounting/account/auto-complete" outlined dense returnObject />
          </v-col>
          <v-col md="3" class="py-3" v-if="invoice.payment_method == 'CASH'">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('Paid Value')" v-model.number="invoice.paid_amount" outlined type="number"
              dense></v-text-field>
          </v-col>
      
      <v-card v-if="invoice.payment_method == 'INSTALLMENT'" md="12" class="my-5 mx-5">
        <v-card-title class="d-flex justify-center">
          <h2>
            {{ $t('Distribution Installments') }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-container id="buffet-table" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
              <tr>
                <th id="idAutoComp">{{ $t('due-date') }}</th>
                <th id="idAutoComp">{{ $t('hr.loan.trans-value') }}</th>
                <th id="idAutoComp">{{ $t('note') }}</th>
                <th></th>
              </tr>
              <v-fade-transition mode="out-in" :group="true" tag="tbody">
                <tr v-for="(el, ix) in installments" :key="ix">
                  <td>
                    <v-text-field full-width dense hide-details  type="date" flat color="#757575" v-model="el.date"
                      @keydown.enter="ToNextInput"></v-text-field>
                  </td>
                  <td>
                    <v-text-field full-width dense hide-details  type="number" flat color="#757575"
                      v-model="el.amount" @keydown.enter="ToNextInput"></v-text-field>
                  </td>
                  <td>
                    <v-text-field @keydown.enter="addNewInstallment" full-width type="text" dense hide-details  flat
                      color="#757575" v-model="el.note"></v-text-field>
                  </td>
                  <td>
                    <v-icon @click="deleteRowI(ix)" color="red"
                      style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                  </td>
                </tr>
              </v-fade-transition>
            </v-simple-table>
          </v-container>
        </v-card-text>
      </v-card>
      <v-col>
        <v-card md="12" class="my-5 mx-5">
          <v-container id="buffet-table" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
              <tr>
                <th v-if="invoice.table_view[0].value" style="padding-left: 100px;  padding-right: 100px;">{{ $t('Material') }}</th>
                <th v-if="invoice.table_view[1].value" id="idAutoComp">{{ $t('Type') }}</th>
                <th v-if="invoice.table_view[2].value" id="idPrice">{{ $t('quantity') }}</th>
                <th v-if="invoice.table_view[3].value">{{ $t('Unit') }}</th>
                <th v-if="invoice.table_view[4].value" id="idPrice">{{ $t('price') }}</th>
                <th v-if="invoice.table_view[5].value" id="idPrice">{{ $t('sum') }}</th>
                <th v-if="invoice.table_view[6].value" id="idPrice">{{ $t('Discount') }}</th>
                <th v-if="invoice.table_view[7].value" id="idPrice">{{ $t('add') }}</th>
                <th v-if="invoice.table_view[8].value" id="idPrice">{{ $t('Net') }}</th>
                <th v-if="invoice.table_view[14].value" id="idAutoComp">{{ $t('Tax Code') }}</th>
                <th v-if="invoice.table_view[16].value" id="idPrice">{{ $t('Tax Percent') }}</th>
                <th v-if="invoice.table_view[17].value" id="idPrice">{{ $t('Included') }}</th>
                <th v-if="invoice.table_view[18].value" id="idPrice">{{ $t('Tax Value') }}</th>
                <th v-if="invoice.table_view[15].value" id="idAutoComp">{{ $t('Tax Account') }}</th>
                <th v-if="invoice.table_view[9].value" id="idAutoComp">{{ $t('Inventory') }}</th>
                <th v-if="invoice.table_view[10].value" id="idAutoComp">{{ $t('Corresponding Account') }}</th>
                <th v-if="invoice.table_view[11].value" id="idAutoComp">{{ $t('Beneficiary') }}</th>
                <th v-if="invoice.table_view[12].value" id="idAutoComp">{{ $t('Beneficiary Percentage') }}</th>
                <th v-if="invoice.table_view[13].value" id="idAutoComp">{{ $t('explain') }}</th>
                <th></th>
              </tr>
              <v-fade-transition mode="out-in" :group="true" tag="tbody">
                <td></td>
                <tr v-for="(material, ix) in invoice.materials" :key="ix">
                  <td v-if="invoice.table_view[0].value">
                    <AutoCompleteField label="" v-model="material.material_id"
                      endPoint="/inventories/materials/auto-complete" returnObject @input="onMaterialInput(material)" />
                  </td>
                  <td v-if="invoice.table_view[1].value">
                    <v-autocomplete full-width dense hide-details flat :items="material.type" item-text="name"
                      item-value="id" v-model="material.type_id"></v-autocomplete>
                  </td>
                  <td v-if="invoice.table_view[2].value">
                    <v-text-field full-width dense hide-details flat type="number" color="#757575"
                      @keydown.enter="ToNextInput" v-model.number="material.quantity"></v-text-field>
                  </td>
                  <td v-if="invoice.table_view[3].value">{{ $t('Piece') }}</td>
                  <td v-if="invoice.table_view[4].value">
                    <v-text-field full-width dense hide-details type="number" flat color="#757575"
                      @keydown.enter="ToNextInput" v-model.number="material.price"></v-text-field>
                  </td>
                  <td v-if="invoice.table_view[5].value">{{ material.quantity * material.price }}</td>
                  <td v-if="invoice.table_view[6].value">
                    <v-text-field full-width dense hide-details type="number" flat color="#757575"
                      @keydown.enter="ToNextInput" v-model.number="material.discount_value"></v-text-field>
                  </td>
                  <td v-if="invoice.table_view[7].value">
                    <v-text-field full-width dense hide-details type="number" flat color="#757575"
                      @keydown.enter="ToNextInput" v-model.number="material.increase_value"></v-text-field>
                  </td>
                  <td v-if="invoice.table_view[8].value">
                    {{
                      material.quantity * material.price -
                      material.discount_value +
                      material.increase_value
                    }}
                  </td>
                  <td v-if="invoice.table_view[14].value">
                    <v-autocomplete full-width dense hide-details flat :items="taxes" item-text="code" item-value="id"
                      v-model="material.tax_id"></v-autocomplete>
                  </td>
                  <td v-if="invoice.table_view[16].value">
                    <v-text-field full-width dense hide-details type="number" v-model="material.tax_percent"
                      @keydown.enter="ToNextInput" flat></v-text-field>
                  </td>
                  <td v-if="invoice.table_view[17].value" class="justify-center">
                    <v-checkbox :dark="$store.state.isDarkMode" v-model="material.is_tax_included" :true-value="1"
                      :false-value="0" class="mx-4 mb-4" hide-details></v-checkbox>
                  </td>
                  <td v-if="invoice.table_view[18].value"> {{ totalTaxI(material) }} </td>
                  <td v-if="invoice.table_view[15].value">
                    <AutoCompleteField label="" dense v-model="material.tax_account_id"
                      endPoint="/accounting/account/auto-complete" returnObject @input="ToNextInput" />
                  </td>
                  <td v-if="invoice.table_view[9].value">
                    <AutoCompleteField label="" dense v-model="material.inventory_id"
                      endPoint="/inventories/auto-complete" returnObject @input="ToNextInput" />
                  </td>
                  <td v-if="invoice.table_view[10].value">
                    <AutoCompleteField label="" dense v-model="material.account_id"
                      endPoint="/accounting/account/auto-complete" returnObject @input="ToNextInput" />
                  </td>
                  <td v-if="invoice.table_view[11].value">
                    <AutoCompleteField label="" v-model="material.beneficiary_account_id"
                      endPoint="/accounting/account/auto-complete" returnObject @input="ToNextInput" clearable />
                  </td>
                  <td v-if="invoice.table_view[12].value">
                    <v-text-field full-width dense hide-details type="number" flat color="#757575"
                      @keydown.enter="ToNextInput" v-model.number="material.beneficiary_percentage"></v-text-field>
                  </td>
                  <td v-if="invoice.table_view[13].value">
                    <v-text-field full-width dense hide-details flat color="#757575" @keydown.enter="addNewMaterial"
                      v-model="material.desc"></v-text-field>
                  </td>
                  <td>
                    <v-icon @click="deleteRow(ix)" color="red"
                      style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                  </td>
                </tr>
              </v-fade-transition>
            </v-simple-table>
          </v-container>
        </v-card>
      </v-col>
      <v-col md="9">
        <v-btn @click="newExpense" class="my-1" color="green" dark>{{ $t('Add Account') }}</v-btn>
        <v-row v-if="invoice.invoice_expenses.length">
          <v-col md="3"></v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Expenses Account') }}</h3>
          </v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Corresponding Account') }}</h3>
          </v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Value') }}</h3>
          </v-col>
          <v-row class="py-0" v-for="(expense, ix) in invoice.invoice_expenses" :key="ix">
            <v-col md="3" class="pt-0">
              <v-btn color="red" dark @click="deleteInvoiceExpense(ix)">
                <v-icon size="20" dark>mdi-delete</v-icon>
                {{ $t('Delete Account') }}
              </v-btn>
            </v-col>
            <v-col md="3" class="py-0">
              <AutoCompleteField label="" v-model="expense.expense_account_id"
                endPoint="/accounting/account/auto-complete" outlined returnObject />
            </v-col>
            <v-col md="3" class="py-0">
              <AutoCompleteField label="" v-model="expense.related_account_id"
                endPoint="/accounting/account/auto-complete" outlined returnObject />
            </v-col>
            <v-col md="3" class="py-0">
              <v-text-field type="number" v-model.number="expense.value" outlined dense></v-text-field>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col md="3"></v-col>
          <v-col md="3"></v-col>
          <v-col md="3"></v-col>
          <v-col md="3" class="pl-0" v-if="invoice.invoice_expenses.length">
            <v-text-field :value="invoice.invoice_expenses.reduce((prev, curr) => prev + curr.value, 0 ) "
               readonly outlined dense :label="$t('Total Expenses')"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="3" style="padding-top: 94px">
        <v-text-field :value="totalQuantities" readonly outlined dense :label="$t('Total Quantities')"></v-text-field>
        <v-text-field readonly :value="invoiceTotalValue()" outlined dense :label="$t('Net Invoice')" ></v-text-field>
        <v-text-field :value="taxTotalValue" readonly outlined dense :label="$t('Tax')"></v-text-field>
        <v-text-field :value="invoiceAfterTaxValue()" readonly outlined dense :label="$t('Invoice Value After Tax')"></v-text-field>
      </v-col>
      <v-col md="4">
        <v-btn color="blue" :disabled="!isNew && is_locked" block @click="createNewInvoice" :loading="submitting">{{ isNew
          ? $t('add') : $t('edit') }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
    
<script>
import axios from "axios";
import AutoCompleteField from "../AutoCompleteField.vue";
import i18n from "../../../../../i18n";
import spinner from "../../../../../components/base/spinner.vue";
export default {
  components: { AutoCompleteField,spinner },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fetching: false,
      installments: [],
      inty: [],
      items: [],
      is_locked: false,
      timeMenu: false,
      timeMenuRef: null,
      invoiceDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      invoiceTime: `${String(new Date().getHours()).length == 1
        ? `0${new Date().getHours()}`
        : new Date().getHours()
        }:${String(new Date().getMinutes()).length == 1
          ? `0${new Date().getMinutes()}`
          : new Date().getMinutes()
        }`,
      invoiceTypeLabel: null,
      fetchingMetadata: false,
      invoiceMetadata: null,
      disscountType: "PERCENTAGE",
      paymentMethods: [
        {
          label: i18n.t('Cash'),
          value: "CASH",
        },
        {
          label: i18n.t('Credit'),
          value: "CREDIT",
        },
        {
          label: i18n.t('Installment'),
          value: "INSTALLMENT",
        },
      ],
      dateMenu: false,
      invoice: {
        name: "",
        client_account_id: null,
        date: null,
        serial: null,
        notes: null,
        discount_value: null,
        discount_type: null,
        invoice_type_id: this.$route.params.id,
        cost_center_id: null,
        inventory_id: null,
        payment_method: null,
        cash_account_id: null,
        discount_account_id: null,
        paid_amount: null,
        is_locked: false,
        invoice_expenses: [],
        materials: [],
        total_amount: 0,
        tax_id: null,
        tax_account_id: null,
        tax_percent: 0,
        table_view: [],
      },
      taxes: [],
      submitting: false,
    };
  },
  computed: {
    totalQuantities() {
      let val = 0;
      if (this.invoice.materials.length) {
        val = this.invoice.materials.reduce((prev, curr) => prev + curr.quantity, 0);
      }
      return parseFloat(val.toFixed(2));
    },
    taxTotalValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
          if (el.tax_value) {
            val += el.tax_value;
          }
        });
      }
     if(val) return parseFloat(val.toFixed(2));
     else return 0;
    },
  },
  methods: {
    invoiceTotalValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
            val += (el.quantity * el.price - el.discount_value) + el.increase_value;
        });
        if (
          this.invoice.discount_type === "FIXED" &&
          this.invoice.discount_value
        ) {
          val -= this.invoice.discount_value;
        } else if (
          this.invoice.discount_type === "PERCENTAGE" &&
          this.invoice.discount_value
        ) {
          val -= val * (this.invoice.discount_value / 100);
        }
      }
      return parseFloat(val.toFixed(2));
    },
    invoiceAfterTaxValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
            val += (el.quantity * el.price - el.discount_value) + el.increase_value;
        });
        if (
          this.invoice.discount_type === "FIXED" &&
          this.invoice.discount_value
        ) {
          val -= this.invoice.discount_value;
        } else if (
          this.invoice.discount_type === "PERCENTAGE" &&
          this.invoice.discount_value
        ) {
          val -= val * (this.invoice.discount_value / 100);
        }
      }
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
          if (!el.is_tax_included) {
            if (el.tax_value) {
            val += el.tax_value;
          }
          }
        });
      }
      if(val) return parseFloat(val.toFixed(2));
     else return 0;
    },
    totalTaxI(mat) {
      let total = (mat.quantity * mat.price) - mat.discount_value + mat.increase_value;
      if (mat.is_tax_included) {
        mat.tax_value = (total * mat.tax_percent) / (100 + parseFloat(mat.tax_percent));
      }
      else {
        mat.tax_value = total * mat.tax_percent / 100
      }
     if(mat.tax_value)  return parseFloat(mat.tax_value.toFixed(2));
     else return 0;
    },
    deleteRowI(index) {
      if (this.installments.length == 1) return;
      this.installments.splice(index, 1);
    },
    addNewInstallment(focus = true) {
      this.installments.push({
        date: null,
        amount: null,
        note: null,
      });
    },
    deleteInvoiceExpense(ix) {
      this.invoice.invoice_expenses.splice(ix, 1);
    },
    async toggleInvoiceLock() {
      await axios.patch(
        `/inventories/invoices/${this.$route.params.id}/toggle-lock`,
        {
          is_locked: this.is_locked,
        }
      );
    },
    async getInvoiceTypeMetadata() {
      try {
        this.fetchingMetadata = true;
        const result = await axios.get(`/inventories/invoices/invoice-type/${this.$route.params.id}`);
        this.invoiceMetadata = result.data.data;
        this.invoice.serial = this.invoiceMetadata.next_serial;
        this.invoice.payment_method = this.invoiceMetadata.invoiceType.payment_method;
        this.invoice.cost_center_id = this.invoiceMetadata.invoiceType.cost_center;
        this.invoice.inventory_id = this.invoiceMetadata.invoiceType.inventory;
        this.invoice.tax_account_id = this.invoiceMetadata.invoiceType.tax_account;
        this.invoice.tax_percent = this.invoiceMetadata.invoiceType.tax_percent;
        this.invoice.tax_id = this.invoiceMetadata.invoiceType.tax_id;
        this.invoice.cash_account_id = this.invoiceMetadata.invoiceType.cash_account;
        this.invoice.discount_account_id = this.invoiceMetadata.invoiceType.discount_account;
        this.invoiceTypeLabel = this.invoiceMetadata.invoiceType.name;
        this.invoice.table_view = this.invoiceMetadata.invoiceType.table_view;
      } finally {
        this.fetchingMetadata = false;
      }
    },
    matchMaterialInventoriesHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.inventory_id = this.invoice.inventory_id;
      });
    },
    matchMaterialTax_IdHandler() {
     this.taxes.forEach((el)=>{
      if(el.id == this.invoice.tax_id){
        this.invoice.tax_percent = el.percent;
        this.matchMaterialTax_PercentHandler();
      }
     });
      this.invoice.materials.forEach((mat) => {
        mat.tax_id = this.invoice.tax_id;
      });
    },
    matchMaterialTax_PercentHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.tax_percent = this.invoice.tax_percent;
      });
    },
    matchMaterialTax_AccHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.tax_account_id = this.invoice.tax_account_id;
      });
    },
    matchMaterialRelatedAccountHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.account_id = this.invoiceMetadata.invoiceType.related_account;
      });
    },
    async createNewInvoice() {
      try {
        this.submitting = true;
        let params = {
          ...this.invoice,
          tax_account_id: this.invoice.tax_account_id?.id,
            inventory_id: this.invoice.inventory_id?.id,
            date: `${this.invoiceDate} ${this.invoiceTime}`,
            total_amount: this.invoiceTotalValue(),
            cost_center_id: this.invoice.cost_center_id?.id,
          client_account_id: this.invoice.client_account_id?.id,
          discount_account_id: this.invoice.discount_account_id?.id,
            cash_account_id:
              this.invoice.payment_method == "CREDIT"
                ? null
                : this.invoice.cash_account_id?.id,
            paid_amount:
              this.invoice.payment_method == "CREDIT"
                ? null
                : this.invoice.paid_amount,
            materials: this.invoice.materials.map((mat) => ({
              ...mat,
              id: mat?.id,
              tax_account_id: mat.tax_account_id?.id,
              inventory_id: mat.inventory_id?.id,
              material_id: mat.material_id?.id,
              type: mat.material_id?.type,
              beneficiary_account_id: mat.beneficiary_account_id?.id,
              account_id: mat.account_id?.id,
            })),
            installments: this.installments.map((mat) => ({
              ...mat,
              date: mat.date,
              amount: mat.amount,
              note: mat.note,

            })),
            invoice_expenses: this.invoice.invoice_expenses.map((el) => ({
            expense_account_id: el.expense_account_id?.id,
            related_account_id: el.related_account_id?.id,
            value: el.value,
          })),
        }
        if (this.isNew) {
          await axios.post("/inventories/invoice-template",params);
        } else {
          await axios.put(`/inventories/invoice-template/${this.$route.params.id}`,params);
        }
        this.$router.go(-1);
      } finally {
        this.submitting = false;
      }
    },
    invoiceTypeFormatter(type) {
      return [
        {
          label: "فاتورة مشتريات",
          value: "PURCHASES",
        },
        {
          label: "فاتورة مبيعات",
          value: "SALES",
        },
        {
          label: "فاتورة مرتجع المشتريات",
          value: "PURCHASE_REFUNDS",
        },
        {
          label: "فاتورة مرتجع المبيعات",
          value: "SALES_REFUNDS",
        },
      ].find((el) => el.value == type).label;
    },
    newExpense() {
      this.invoice.invoice_expenses.push({
        expense_account_id: null,
        related_account_id: null,
        value: null,
      });
    },
    deleteRow(index) {
      if (this.invoice.materials.length == 1) return;
      this.invoice.materials.splice(index, 1);
    },
    async onMaterialInput(material) {
      this.ToNextInput();
      console.log(material, "material");
      const result = await axios.get(
        `/inventories/materials/${material.material_id.id}`
      );
      const resultMetadata = await axios.get(
        `/inventories/invoices/invoice-type/${this.$route.params.id}`
      );
      console.log(resultMetadata, "META");
      const metadataTemp = resultMetadata.data.data.invoiceType;
      const dataTemp = result.data.data;
      const invoiceType = resultMetadata.data.data.invoiceType.type;
      if (invoiceType == "PURCHASES") {
        if (dataTemp.purchases_account) {
          material.account_id = dataTemp.purchases_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      } else if (invoiceType == "SALES") {
        if (dataTemp.sales_account) {
          material.account_id = dataTemp.sales_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      } else if (invoiceType == "PURCHASE_REFUNDS") {
        if (dataTemp.purchases_refund_account) {
          material.account_id = dataTemp.purchases_refund_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      } else {
        if (dataTemp.sales_account) {
          material.account_id = dataTemp.sales_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      }
      material.type = dataTemp.values;
      if ((invoiceType == "PURCHASE_REFUNDS" || invoiceType == "SALES") && dataTemp.input_tax_id && this.invoice.tax_account_id != null) {
        material.tax_id = dataTemp.input_tax_id;
        material.tax_percent = dataTemp.tax_percent;
      }
      if ((invoiceType == "PURCHASE" || invoiceType == "SALES_REFUNDS") && dataTemp.output_tax_id && this.invoice.tax_account_id != null) {
        material.tax_id = dataTemp.output_tax_id;
        material.tax_percent = dataTemp.tax_percent;
      }
    },
    addNewMaterial(focus = true) {
      this.invoice.materials.push({
        material_id: null,
        type: null,
        type_id: null,
        quantity: 0,
        price: 0,
        discount_value: 0,
        increase_value: 0,
        account_id: this.invoiceMetadata.invoiceType.related_account,
        beneficiary_account_id: null,
        beneficiary_percentage: null,
        desc: null,
        inventory_id: this.invoice.inventory_id,
        tax_account_id: this.tax_account_id,
        tax_id: this.invoice.tax_id,
        tax_percent: this.invoice.tax_percent,
        tax_value: 0,
        is_tax_included: 0,
      });
      if (focus) {
        setTimeout(() => {
          this.ToNextInput();
        }, 0);
      }
    },
    ToNextInput() {
      const inputs = Array.prototype.slice.call(document.querySelectorAll("table input:not([type=hidden])"));
      const currInput = document.activeElement;
      const currInputIndex = inputs.indexOf(currInput);
      const nextinputIndex = currInputIndex + 1;
      const input = inputs[nextinputIndex];
      input.focus();
    },
  },
  async created() {
    this.fetching = true;
    if (this.isNew) {
      await this.getInvoiceTypeMetadata();
      this.addNewMaterial(false);
      this.addNewInstallment();
    } else {
      const result = await axios.get(`/inventories/invoice-template/${this.$route.params.id}`);
      const dataTemp = result.data.data;
      this.invoice.name = dataTemp.name;
      this.invoice.invoice_type_id = dataTemp.invoice_type.id;
      this.invoice.invoice_expenses = dataTemp.invoice_expenses.map((el) => ({
        expense_account_id: el.expense_account,
        related_account_id: el.related_account,
        value: el.value,
      }));
      this.invoice.materials = dataTemp.invoice_materials.map((el) => ({
        ...el,
        material_id: el.material,
        inventory_id: el.inventory,
        account_id: el.account,
        beneficiary_account_id: el.beneficiary_account,
      }));
      this.installments = dataTemp.installments.map((el) => ({
        ...el,
        date: el.date,
        amount: el.amount,
        note: el.note,
      }));
      this.invoice.tax_account_id = dataTemp.invoice_type.tax_account;
      this.invoice.cost_center_id = dataTemp.cost_center;
      this.invoice.inventory_id = dataTemp.inventory;
      this.invoice.cash_account_id = dataTemp.cash_account;
      this.invoice.discount_account_id = dataTemp.discount_account;
      this.invoice.discount_type = dataTemp.discount_type;
      this.invoice.discount_value = dataTemp.discount_value;
      this.invoice.tax_percent = dataTemp.invoice_type.tax_percent;
      this.invoice.tax_id = dataTemp.invoice_type.tax_id;  
      this.invoiceTypeLabel = dataTemp.invoice_type.name;
      this.invoice.paid_amount = dataTemp.paid_amount;
      this.invoice.payment_method = dataTemp.payment_method;
      this.invoice.table_view = dataTemp.invoice_type.table_view;
    }
    this.addNewInstallment();
    let params = { paginated: 'false',};
    if(this.returnAbility('tax:index')){
        let res = await axios.get(`/accounting/tax`, { params });
        this.taxes = res.data.data;
      }
      this.fetching = false;
  },
};
</script>
<style scoped lang="scss">
table {
  border-collapse: collapse;
  table-layout: fixed;
}

.tableId {
  border-radius: 10%;
}

td,
th {
  padding: 0px 10px;
  border-collapse: collapse;
  border-radius: 10%;
}

table,
th,
td {
  border: 1px solid #000;
  border-collapse: collapse;
  border-radius: 10%;
}

#idAutoComp {
  padding-left: 70px;
  padding-right: 70px;
}

#idPrice {
  padding-left: 40px;
  padding-right: 40px;
}

#buffet-table {
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
}

#buffet-table th {
  color: #909399;
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
  text-align: center;
  width: max-content;

}

#buffet-table tr {
  transition: 0.5s;
}

#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
  width: max-content;
}

.columnSelector:hover {
  border-right: 2px solid blue;
}
</style>
